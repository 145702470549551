import React from "react";

export const Menu = ({ props }) => (
  <svg
    viewBox="0 0 32 32"
    width="24"
    height="24"
    fill="none"
    stroke="currentcolor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    {...props}
  >
    <circle cx="16" cy="7" r="2" />
    <circle cx="16" cy="16" r="2" />
    <circle cx="16" cy="25" r="2" />
  </svg>
);

export const Left = ({ props }) => (
  <svg
    viewBox="0 0 32 32"
    width="24"
    height="24"
    fill="none"
    stroke="currentcolor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    {...props}
  >
    <path d="M20 30 L4 16 20 2" />
  </svg>
);

export const Right = ({ props }) => (
  <svg
    viewBox="0 0 32 32"
    width="24"
    height="24"
    fill="none"
    stroke="currentcolor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    {...props}
  >
    <path d="M12 30 L28 16 12 2" />
  </svg>
);

export const Broccoli = ({ props }) => (
  <svg width="24" height="24" viewBox="0 0 56.745 56.745" {...props}>
    <path
      fill="#88C057"
      d="M45.146 13.7c0-.996-.563-1.852-1.387-2.308.39-.796.61-1.684.61-2.626 0-3.353-2.77-6.07-6.18-6.07-.89 0-1.74.188-2.51.523-.92-1.24-2.39-2.05-4.07-2.05-1.9 0-3.55 1.03-4.4 2.55-.95-.65-2.09-1.04-3.33-1.04-3.2 0-5.8 2.55-5.8 5.69 0 .23.02.46.04.69-2.277.77-3.91 2.88-3.91 5.37 0 1.94.992 3.66 2.51 4.68-.607.816-.968 1.816-.968 2.9 0 2.726 2.25 4.935 5.02 4.935 1.16 0 2.23-.39 3.08-1.04 1.12 1.544 2.956 2.556 5.036 2.556 2.2 0 4.125-1.13 5.22-2.83.74 1.24 2.1 2.07 3.666 2.07 2.342 0 4.25-1.87 4.25-4.173 0-.517-.1-1.01-.28-1.467 1.523-.63 2.592-2.114 2.592-3.842 0-.82-.25-1.58-.66-2.22.842-.45 1.43-1.32 1.43-2.33z"
    />
    <path
      fill="#659C35"
      d="M22.832 26.543c-.62.27-1.298.437-2.02.437-2.774 0-5.022-2.21-5.022-4.933 0-1.086.36-2.086.966-2.9-1.516-1.027-2.51-2.743-2.51-4.69 0-.94.234-1.83.647-2.61-1.657-.985-3.838-.786-5.27.62-.125.123-.238.254-.345.388-1.607-.77-3.6-.503-4.934.808-1.04 1.023-1.436 2.44-1.19 3.762-.754.115-1.48.455-2.06 1.026-1.458 1.44-1.458 3.76 0 5.19.61.6 1.38.95 2.175 1.04-.24 1.4.18 2.88 1.27 3.95 1.16 1.14 2.77 1.54 4.26 1.21-.28 1.03-.01 2.18.82 2.99 1.23 1.21 3.23 1.21 4.46 0 .27-.27.49-.58.64-.91 1.14.46 2.49.23 3.42-.68.43-.43.71-.95.84-1.51.68.21 1.45.05 2-.48.53-.52.69-1.26.5-1.93.48-.16.92-.43 1.33-.75zm32.652-7.293c-.126-.125-.26-.236-.396-.34.784-1.58.512-3.537-.823-4.848-1.04-1.023-2.483-1.41-3.83-1.168-.118-.74-.463-1.454-1.044-2.024-1.45-1.433-3.82-1.433-5.28 0-.09.094-.17.198-.25.3l-.09.223c.83.455 1.39 1.312 1.39 2.307 0 1.012-.58 1.88-1.43 2.33.42.643.66 1.404.66 2.223 0 1.728-1.07 3.21-2.59 3.845.18.457.28.95.28 1.467 0 2.305-1.9 4.174-4.25 4.174-.55 0-1.07-.11-1.55-.3.05.06.08.13.14.18.44.43.98.7 1.54.83-.2.67-.04 1.43.5 1.96.53.52 1.29.68 1.968.49.22.62.58 1.2 1.08 1.7 1.8 1.76 4.708 1.76 6.5 0 .47-.46.81-1 1.04-1.57 1.14.18 2.35-.16 3.23-1.03.996-.98 1.31-2.37.946-3.62.845-.148 1.654-.537 2.306-1.178 1.68-1.65 1.68-4.33 0-5.986z"
    />
    <path
      fill="#436B1C"
      d="M40.387 30.918c-.677.186-1.43.027-1.964-.496-.317-.31-.503-.7-.56-1.104-.034-.225-.11-.442-.223-.635-3.918 4.044-6.655 11.494-6.655 11.494 1.3-4.626 1.096-9.242.693-12.32-.83.408-1.764.64-2.756.64-.966 0-1.875-.225-2.688-.614 1.75 6.19.75 16.294.75 16.294-1.873-7.495-5.993-12.18-7.94-14.058-.14.1-.264.23-.352.39-.14.25-.32.49-.54.71-.924.91-2.28 1.13-3.42.68-.153.33-.364.64-.64.91-.33.32-.718.55-1.128.7 5.02 0 10.78 9.99 11.523 20.65.055.78.712 1.39 1.498 1.39h5c.77 0 1.412-.58 1.5-1.34.458-4.06 1.875-21.02 9.745-21.02-.27-.17-.525-.37-.76-.6-.504-.5-.862-1.08-1.083-1.7z"
    />
    <path
      fill="#659C35"
      d="M23.468 15.177c-.12 0-.244-.022-.364-.07-2.517-.983-3.765-3.832-2.78-6.35.983-2.515 3.827-3.763 6.35-2.78.514.2.77.78.567 1.294-.2.52-.77.77-1.29.57-1.49-.58-3.17.16-3.76 1.65-.58 1.49.16 3.18 1.65 3.76.52.2.77.78.57 1.3-.15.4-.53.64-.93.64z"
    />
    <path
      fill="#88C057"
      d="M49.084 25.647c-.604 0-1.207-.113-1.786-.34-1.22-.476-2.18-1.398-2.705-2.598-.222-.51.01-1.1.515-1.32.51-.22 1.097.01 1.317.51.31.71.88 1.25 1.602 1.54.723.28 1.51.26 2.22-.05.71-.31 1.257-.88 1.54-1.6.28-.73.266-1.51-.046-2.22-.22-.51.01-1.1.52-1.32.51-.22 1.1.01 1.32.51.53 1.2.56 2.53.08 3.75s-1.4 2.18-2.6 2.7c-.63.27-1.29.41-1.96.41z"
    />
    <path
      fill="#659C35"
      d="M35.39 23.175c-1.71 0-3.374-.896-4.274-2.49-.272-.48-.103-1.09.378-1.362.48-.272 1.09-.103 1.362.378.382.68 1.003 1.17 1.75 1.37.745.21 1.53.11 2.204-.27s1.16-1 1.37-1.75c.206-.74.11-1.53-.27-2.2-.273-.48-.104-1.09.377-1.36.48-.27 1.09-.1 1.362.38.64 1.14.8 2.47.45 3.73s-1.17 2.31-2.31 2.96c-.76.43-1.59.64-2.4.64zm-3.51-5.397c-.715 0-1.44-.157-2.127-.49-2.433-1.177-3.454-4.114-2.277-6.547.24-.49.837-.7 1.336-.46.497.24.705.84.465 1.34-.697 1.44-.093 3.18 1.348 3.88 1.44.7 3.18.1 3.876-1.35.24-.49.84-.7 1.34-.46.5.24.71.84.47 1.34-.84 1.75-2.6 2.77-4.42 2.77zm-7.6 5.612c-.668 0-1.334-.137-1.963-.412-2.476-1.083-3.61-3.978-2.528-6.455.22-.504.8-.737 1.31-.516.5.22.73.81.51 1.317-.64 1.466.03 3.18 1.49 3.82.71.312 1.5.327 2.22.044.72-.283 1.29-.83 1.6-1.54.22-.504.8-.736 1.31-.516.5.22.73.81.51 1.317-.52 1.2-1.48 2.12-2.7 2.6-.58.22-1.19.34-1.79.34zm11.124-10.91c-.158 0-.317-.008-.477-.023-1.304-.125-2.48-.75-3.313-1.76-.352-.426-.29-1.056.135-1.408.42-.36 1.05-.29 1.4.13.49.6 1.19.97 1.96 1.04.78.08 1.53-.16 2.12-.65 1.23-1.02 1.41-2.85.39-4.09-.36-.43-.29-1.06.13-1.41.42-.36 1.05-.29 1.4.13 1.72 2.08 1.42 5.18-.66 6.9-.89.73-1.98 1.12-3.12 1.12z"
    />
    <path
      fill="#88C057"
      d="M9.882 24.546c-.613 0-1.235-.115-1.837-.36-2.504-1.015-3.715-3.88-2.7-6.384.208-.512.79-.757 1.304-.55.51.207.75.79.55 1.302-.61 1.482.11 3.178 1.59 3.78 1.48.604 3.18-.115 3.78-1.598.21-.513.79-.758 1.3-.55.51.207.76.79.55 1.302-.78 1.902-2.61 3.058-4.55 3.058z"
    />
  </svg>
);

export const Sugarplum = ({ width = "80", height, rest }) => (
  <svg viewBox="0 0 80 114" width={width} height={height} {...rest}>
    <defs>
      <path
        id="a"
        d="M7.08.47C4.8 6.35 4.97 14.05 6.13 20.29c-.95.14-2.4.23-4.5.73-1.69.4-1.27 2.74.48 2.65 2.97-.15 4.02-.15 5.96-.27 1.02-.05 2.14-.77 1.85-1.94a54.11 54.11 0 0 1-1.55-9.92c-.18-3.45.2-6.83.5-10.26C8.92.7 8.15.15 7.6.15c-.23 0-.42.1-.51.32z"
      />
      <path
        id="c"
        d="M.97 1.73c.37 3.37.97 6.69 1.15 10.08a56.1 56.1 0 0 1-.41 10.4c-.1.78.3 1.71 1.2 1.85 2.2.32 4.34.7 7.13.6 1.23-.06 1.14-1.89.2-2.42-1.4-.8-3.46-.94-5.26-1.08C5.53 14.61 5.08 6.8 2.3.8a.39.39 0 0 0-.38-.24C1.5.56.9 1.12.97 1.73z"
      />
      <path
        id="e"
        d="M6.68.38c-1.22 1.1-1.96 2.8-2.66 4.28A50.96 50.96 0 0 0 2 9.53c-1.13 3.15-2.19 7-1.65 10.38.26 1.6 2.37.98 2.54-.35.22-1.7.14-3.4.42-5.1.27-1.62.67-3.24 1.13-4.81.44-1.51.98-2.98 1.5-4.46C6.49 3.73 7.21 2.2 7.28.63 7.28.4 7.11.28 6.92.28a.36.36 0 0 0-.24.1z"
      />
      <path
        id="g"
        d="M.54.63c.07 1.57.8 3.1 1.32 4.56.53 1.48 1.07 2.95 1.5 4.46.47 1.57.86 3.18 1.13 4.81.29 1.7.2 3.4.42 5.1.17 1.33 2.28 1.95 2.54.35.54-3.38-.52-7.23-1.64-10.38A50.96 50.96 0 0 0 3.8 4.66C3.08 3.19 2.34 1.48 1.13.38a.36.36 0 0 0-.25-.1C.71.28.53.41.54.63z"
      />
      <path
        id="i"
        d="M33.51.2C20.78.7 12.81 9.59 8.45 28.18c-.85 3.58-1.93 6.3-3.07 9.2-2.06 5.18-4.18 10.55-4.8 20.3-.63 9.73 3.34 20.12 10.1 26.47 6.84 6.42 16.04 9.8 25.93 9.51h.02c9.87-.37 18.83-4.34 25.23-11.2 6.34-6.78 9.62-17.41 8.36-27.08-1.27-9.68-3.74-14.9-6.13-19.94a61.55 61.55 0 0 1-3.67-8.98C54.96 8.56 46.64.2 34.26.2h-.75z"
      />
      <path
        id="k"
        d="M33.1.14C20.36.64 12.4 9.53 8.03 28.12c-.84 3.58-1.92 6.3-3.06 9.2C2.9 42.5.79 47.86.16 57.6c-.62 9.74 3.35 20.13 10.11 26.48 6.84 6.42 16.04 9.8 25.93 9.51h.02c9.87-.36 18.83-4.34 25.23-11.2 6.34-6.78 9.61-17.41 8.35-27.08-1.26-9.68-3.74-14.9-6.13-19.94a61.9 61.9 0 0 1-3.66-8.98C54.55 8.5 46.23.13 33.85.13h-.75z"
      />
      <path
        id="m"
        d="M32.3.54h-.7c-11.67.47-18.73 8.54-22.87 26.18a63.47 63.47 0 0 1-3.16 9.52c-2 5.02-4.05 10.2-4.65 19.58-.58 9.06 3.1 18.72 9.37 24.61 6.38 6 14.99 9.15 24.23 8.88h.03c9.24-.34 17.62-4.05 23.6-10.45 5.87-6.3 8.9-16.18 7.73-25.19-1.21-9.3-3.6-14.35-5.92-19.23-1.37-2.88-2.66-5.6-3.78-9.29C50.99 8.17 43.62.54 32.3.54z"
      />
      <path
        id="o"
        d="M28.02.07C18.75.45 12.25 6.44 8.18 23.75 5.69 34.35 1.35 38.13.44 52.33c-.51 8.03 2.66 16.7 8.36 22.04 5.76 5.4 13.58 8.24 21.92 8 8.33-.3 15.96-3.65 21.35-9.43 5.34-5.7 7.93-14.56 6.9-22.54-1.85-14.1-6.42-17.6-9.6-28.01C44.29 5.73 37.58.07 28.61.07h-.59z"
      />
      <path
        id="q"
        d="M29.64.53c-4.8.16-7.78 3.24-8.89 4.63a.68.68 0 0 1-1.03.05c-1.19-1.28-4.29-4.05-8.78-4.05h-.37C3.97 1.4-.01 6.62.22 13.57l.02.49C.2 7.33 4.15 2.33 10.6 2.12a9.02 9.02 0 0 1 .37-.01c4.5 0 7.6 2.77 8.78 4.05a.68.68 0 0 0 1.03-.05c1.1-1.39 4.08-4.47 8.9-4.63h.41c6.21 0 10.3 4.65 10.69 11.21v-.48C40.54 5.4 36.4.52 30.05.52l-.42.01z"
      />
      <path
        id="s"
        d="M30.07.65c-4.88.15-7.9 3.2-9.02 4.56a.7.7 0 0 1-1.04.04c-1.24-1.29-4.53-4.13-9.3-3.98C4.04 1.5 0 6.63.22 13.48c.15 4.27 1.7 7.02 3.7 9.78 4.82 6.65 12.72 10.03 16.17 11.26.93.33 1.95.3 2.86-.1 3.36-1.44 11.02-5.33 15.39-12.27 1.82-2.9 3.17-5.73 3.03-10C41.13 5.45 36.94.64 30.49.64h-.42z"
      />
      <path
        id="u"
        d="M29.64.7c-4.8.16-7.78 3.2-8.89 4.57a.68.68 0 0 1-1.02.04c-1.23-1.29-4.47-4.13-9.16-3.98C3.97 1.55-.01 6.7.22 13.55c.14 4.26 1.66 7 3.64 9.77C8.6 29.98 16.4 33.35 19.8 34.59a3.8 3.8 0 0 0 2.81-.1c3.32-1.44 10.86-5.33 15.17-12.27 1.8-2.89 3.13-5.73 3-10C40.54 5.51 36.4.7 30.05.7h-.42z"
      />
      <path
        id="w"
        d="M22.8 1.14c-3.4 1.57-4.3 4.12-5.56 4.12h-.02c-.57-.02-1.44-.86-2.1-1.62-.02 0-2.28-2.82-6.53-2.82-.53 0-1.09.05-1.67.14C3.69 1.5.83 4.02.74 7.5c-.07 2.46.58 4.96 2.07 6.94 2.43 3.2 5.88 6 9.58 7.62 2.4 1.06 4.1 1.69 5.73 1.69 2.49 0 4.81-1.47 9.27-5.13 4.51-3.7 6.27-7.71 6.28-11.16 0-4.19-2.99-7.23-6.98-7.23-1.23 0-2.54.28-3.9.91z"
      />
      <path
        id="y"
        d="M10.18.76c-.56.38-.81 1.03-1.3 1.5A4.14 4.14 0 0 1 7 3.24c-2.05.48-4-.37-5.17-2.03C1.42.62.54.74.33 1.48c-.04.16.01.38.04.55C.6 3.88 3.2 5.74 5.63 5.82c2.54.08 5.35-1.61 5.85-3.95.02-.11 0-.24 0-.36 0-.34-.08-.73-.37-.9a.58.58 0 0 0-.3-.08c-.22 0-.45.1-.63.23z"
      />
      <path
        id="A"
        d="M1.7 1.4C.22 2.16.14 3.56 1.3 4.78c.14.16.32.3.5.41.52.32 1.27.51 2.02.51 1.16 0 2.3-.48 2.51-1.76C6.68 1.92 5.06 1 3.45 1c-.62 0-1.24.14-1.75.4z"
      />
      <path
        id="C"
        d="M.94 1.86c-1 1.78-.35 5.17 2.3 4.75 2-.32 2.33-3.17 1.6-4.54a2.48 2.48 0 0 0-.38-.53A2.3 2.3 0 0 0 2.78.77a2.1 2.1 0 0 0-1.84 1.1z"
      />
      <path
        id="E"
        d="M.48 1.45C-.53 3.23.13 6.62 2.78 6.2c2-.32 2.33-3.18 1.6-4.54A2.48 2.48 0 0 0 4 1.13 2.3 2.3 0 0 0 2.3.36 2.1 2.1 0 0 0 .48 1.45z"
      />
      <path
        id="G"
        d="M.3 3.28C.5 4.6 1.81 5.12 3.13 5.12c.77 0 1.55-.18 2.1-.46.2-.11.4-.25.57-.4 1.3-1.18 1.25-2.6-.36-3.4C4.87.56 4.15.4 3.42.4 1.69.41-.02 1.3.29 3.28z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(22 90.1)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#437206"
          d="M-2.38 26.61h15.29v-29.4H-2.38z"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(51 89.1)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#437206"
          d="M-1.98 27.61H13.9V-2.38H-1.98z"
          mask="url(#d)"
        />
      </g>
      <g transform="translate(0 54.1)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path fill="#437206" d="M-2.72 23.8H10.2V-2.66H-2.72z" mask="url(#f)" />
      </g>
      <g transform="translate(72 54.1)">
        <mask id="h" fill="#fff">
          <use xlinkHref="#g" />
        </mask>
        <path fill="#437206" d="M-2.4 23.8h12.94V-2.66H-2.4z" mask="url(#h)" />
      </g>
      <g transform="translate(5 .1)">
        <mask id="j" fill="#fff">
          <use xlinkHref="#i" />
        </mask>
        <path
          fill="#437206"
          d="M-2.43 96.62h75.85V-2.75H-2.43z"
          mask="url(#j)"
        />
      </g>
      <g transform="translate(6 3.1)">
        <mask id="l" fill="#fff">
          <use xlinkHref="#k" />
        </mask>
        <path fill="#437206" d="M-2.84 96.56H73V-2.81H-2.84z" mask="url(#l)" />
      </g>
      <g transform="translate(7 2.1)">
        <mask id="n" fill="#fff">
          <use xlinkHref="#m" />
        </mask>
        <path
          fill="#B7D600"
          d="M-2.08 92.27h71.15V-2.4H-2.08z"
          mask="url(#n)"
        />
      </g>
      <g transform="translate(11 6.1)">
        <mask id="p" fill="#fff">
          <use xlinkHref="#o" />
        </mask>
        <path
          fill="#DFEA00"
          d="M-2.55 85.33h64.68v-88.2H-2.55z"
          mask="url(#p)"
        />
      </g>
      <g transform="translate(20 42.1)">
        <mask id="r" fill="#fff">
          <use xlinkHref="#q" />
        </mask>
        <path
          fill="#EEF53B"
          d="M-2.73 16.99h46.45v-19.4H-2.73z"
          mask="url(#r)"
        />
      </g>
      <g transform="translate(20 46.1)">
        <mask id="t" fill="#fff">
          <use xlinkHref="#s" />
        </mask>
        <path fill="#BFD300" d="M-2.73 37.68H44.3V-2.3H-2.73z" mask="url(#t)" />
      </g>
      <g transform="translate(20 43.1)">
        <mask id="v" fill="#fff">
          <use xlinkHref="#u" />
        </mask>
        <path
          fill="#844E00"
          d="M-2.73 37.74h46.45V-2.24H-2.73z"
          mask="url(#v)"
        />
      </g>
      <g transform="translate(23 47.1)">
        <mask id="x" fill="#fff">
          <use xlinkHref="#w" />
        </mask>
        <path fill="#9A5A02" d="M-2.2 26.69h38.8v-29.4H-2.2z" mask="url(#x)" />
      </g>
      <g transform="translate(34 32.1)">
        <mask id="z" fill="#fff">
          <use xlinkHref="#y" />
        </mask>
        <path
          fill="#475B08"
          d="M-2.62 8.76h17.05V-2.41H-2.62z"
          mask="url(#z)"
        />
      </g>
      <g transform="translate(25 28.1)">
        <mask id="B" fill="#fff">
          <use xlinkHref="#A" />
        </mask>
        <path fill="#EE7651" d="M-2.44 8.64H9.32V-1.94H-2.44z" mask="url(#B)" />
      </g>
      <g transform="translate(45 20.1)">
        <mask id="D" fill="#fff">
          <use xlinkHref="#C" />
        </mask>
        <path fill="#475B08" d="M-2.45 9.59H8.14V-2.17H-2.45z" mask="url(#D)" />
      </g>
      <g transform="translate(29 21.1)">
        <mask id="F" fill="#fff">
          <use xlinkHref="#E" />
        </mask>
        <path fill="#475B08" d="M-2.91 9.18H7.67V-2.58H-2.9z" mask="url(#F)" />
      </g>
      <g transform="translate(47 28.1)">
        <mask id="H" fill="#fff">
          <use xlinkHref="#G" />
        </mask>
        <path fill="#EE7651" d="M-2.68 8.05H9.66V-2.53H-2.68z" mask="url(#H)" />
      </g>
    </g>
  </svg>
);
